/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <section>
            <p>
              <center>
                {" "}
                <strong>ALEX & IGGY</strong> is a creative platform for Alex
                Covo and Iggy - partners in love, life and creativity! It's a
                creative outlet for our ideas to flow freely with no boundaries.
                We always love hearing from you. We aspire to create distinctive
                imagery with a style influenced by the romanticism and moodiness
                that is a common thread in the paintings and photographs from
                our favorite artists. Enjoy our work and feel free to reach out
                and say hello!
                <p />
                <center>
                  <a href={`https://instagram.com/${social.instagram}`}>
                    You should follow us on Instagram
                  </a>
                </center>
                <Image
                  fixed={data.avatar.childImageSharp.fixed}
                  alt={author}
                  imgStyle={{
                    borderRadius: `50%`,
                  }}
                />
                This site is brought to you by{" "}
                <a href={`https://alexcovo.com/`}>
                  <strong>{author}</strong>{" "}
                </a>{" "}
                who lives and works in NYC making random beautiful things.
                {` `}
              </center>
            </p>
          </section>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          instagram
        }
      }
    }
  }
`

export default Bio
